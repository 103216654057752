import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { breakPoints, colors, measurments, shadows } from "../../utils/config";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ProfileDropdown from "../ProfileDropdown";
import Tabber from "../../sharedUI/Tabber";
import SVG from "../../sharedUI/SVG";
import { useCache } from "../../caching/caches";

const StyledNavigation = styled.nav`
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  .nav-inner {
    padding: 1rem;
    align-items: center;
    display: grid;
    grid-template-columns: minmax(200px, auto);
    grid-template-areas: "logo menu profile";
    gap: 0.5rem;
    .logo {
      grid-area: logo;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .logo-bg {
        background: ${colors.neutral[90]};
        padding: 0.5rem;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          object-fit: contain;
        }
      }
    }
  }
  .main-menu {
    grid-area: menu;
    overflow-y: auto;
    margin-right: auto;
  }
  .profile-menu {
    grid-area: profile;
    display: flex;
    justify-content: flex-end;
  }
  transition: 300ms ease-in-out;
  &.is-scrolled {
    box-shadow: ${shadows[1]};
    background: ${colors.neutral[30]};
  }

  ${breakPoints.tablet} {
    .nav-inner {
      padding: 0;
      grid-template-areas:
        "logo profile"
        "menu menu";
    }
    .main-menu {
      margin-right: unset;
    }
    .logo,
    .profile-menu {
      padding: 1rem;
    }
  }
`;

const StyledNavSpacer = styled.div`
  height: ${measurments.navHeight + 30}px;
  ${breakPoints.tablet} {
    height: ${measurments.navHeight * 2 + 30}px;
  }
`;

const baseRoutes = [
  {
    name: "Start",
    path: "/",
  },
  {
    name: "Grundutbildning",
    path: "/kurser",
  },
  {
    name: "Övningsbank",
    path: "/ovningsbank",
  },
  {
    name: "Träningsplanering",
    path: "/traningsplaneringar",
  },
  {
    name: "Arrangemang",
    path: "/arrangemang",
  },
];

const documentsRoute = {
  name: "Information",
  path: "/dokument",
};

const musicRoute = {
  name: "Musik",
  path: "/musik",
};

const Navigation = ({ globalState, setGlobalState }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state: documents } = useCache("documents");
  const { state: musics } = useCache("music");

  const routes = useMemo(() => {
    const routesToReturn = [...baseRoutes];
    if (documents.length) routesToReturn.push(documentsRoute);
    if (musics.length) routesToReturn.push(musicRoute);
    return routesToReturn;
  }, [documents, musics]);

  const menuContainerRef = useRef(null);

  const setActiveTab = (i) => {
    const match = routes[i];
    if (!match) return;
    navigate(match.path);
  };
  const activeTab = useMemo(() => {
    const basePath = location.pathname.split("/")[1];
    let match = -1;
    match = routes.findIndex((r) => r.path.split("/")[1] === basePath);
    if (basePath === "kurs") {
      match = routes.findIndex((r) => r.path === "/kurser");
    }
    return match;
  }, [location, routes]);

  const [isScrolled, setIsScrolled] = useState(false);

  const signOut = () => {
    window.localStorage.removeItem("bg-kurswebb-user-token");
    navigate("/login");
    setGlobalState({
      ...globalState,
      user: null,
      token: null,
    });
  };

  useEffect(() => {
    const handler = () => {
      const y = window.scrollY;
      if (y > 30) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  useEffect(() => {
    const match = routes[activeTab];
    if (!match) return;
    const newTabEl = document.getElementById(`tabber_${match.name}`);
    if (newTabEl)
      newTabEl.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
  }, [activeTab, routes]);

  return (
    <>
      <StyledNavigation className={`${isScrolled ? "is-scrolled" : ""}`}>
        <div className="nav-inner">
          <Link to="/" className="logo">
            <div className="logo-bg">
              <SVG name="bg-logo" />
            </div>
            <h1 className="h3 m0">Tränarportalen</h1>
          </Link>
          <div className="main-menu" ref={menuContainerRef}>
            <Tabber
              tabs={routes.map((r) => r.name)}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="profile-menu">
            <ProfileDropdown signOut={signOut} user={globalState.user} />
          </div>
        </div>
      </StyledNavigation>
      <StyledNavSpacer />
    </>
  );
};

export default Navigation;
